{
  "name": "short-unique-id",
  "version": "5.0.3",
  "description": "Generate random or sequential UUID of any length",
  "keywords": [
    "short",
    "random",
    "uid",
    "uuid",
    "guid",
    "node",
    "unique id",
    "generator",
    "tiny"
  ],
  "bin": {
    "short-unique-id": "bin/short-unique-id",
    "suid": "bin/short-unique-id"
  },
  "main": "dist/short-unique-id.js",
  "types": "dist/short-unique-id.d.ts",
  "homepage": "https://shortunique.id",
  "repository": {
    "type": "git",
    "url": "https://github.com/jeanlescure/short-unique-id"
  },
  "license": "Apache-2.0",
  "runkitExampleFilename": "./runkit.js",
  "scripts": {
    "test": "ts-node src/test.ts",
    "build": "./scripts/build",
    "docs": "./scripts/docs",
    "release": "release-it"
  },
  "release-it": {
    "git": {
      "changelog": "auto-changelog --stdout -l false -u -t ./assets/changelog-compact.hbs"
    },
    "hooks": {
      "after:bump": "./scripts/release"
    },
    "npm": {
      "publish": false
    }
  },
  "files": [
    "bin",
    "dist",
    "runkit.js",
    "package.json"
  ],
  "devDependencies": {
    "@types/node": "^20.3.2",
    "auto-changelog": "^2.4.0",
    "esbuild": "^0.18.10",
    "refup": "^1.1.0",
    "release-it": "^15.11.0",
    "ts-node": "^10.9.1",
    "tslib": "^2.6.0",
    "typedoc": "^0.24.8",
    "typedoc-plugin-extras": "^2.3.3",
    "typedoc-plugin-rename-defaults": "^0.6.5",
    "typedoc-plugin-script-inject": "^1.0.0",
    "typescript": "^5.1.6"
  }
}
